<template>
  <div>

  </div>
</template>

<script>


export default {
  name: 'Dashboard',
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
